import React from 'react';

const Dirgahayu = () => {
    const currentDate = new Date();
    const currentYear = new Date().getFullYear();

    const startDate = new Date(currentYear + '-09-01');
    const endDate = new Date(currentYear + '-10-10');

    const isWithinRange = currentDate >= startDate && currentDate < endDate;

    return (
        <div>
            {isWithinRange && (
                <div className="text-white relative">
                    <div className="container  mx-auto text-center p-8 mb-10 bg-black rounded-2xl shadow-2xl bg-opacity-50">
                        {/*<div className='flex flex-col items-center'>*/}
                        {/*    <div className='bg-red-600 h-10 w-40'></div>*/}
                        {/*    <div className='bg-white h-10 w-40'></div>*/}
                        {/*</div>*/}
                        <h1 className="desktop:text-4xl phone:text-xl mb-4">Launching website Inspektorat
                            Kabupaten Paniai Oleh Penjabat Bupati, </h1>
                        <h1 className="desktop:text-5xl phone:text-xl font-bold mb-4">Dr. MARTHA PIGOME, SH., M.Hum</h1>

                    </div>
                </div>
            )}
        </div>
    );
};

export default Dirgahayu;