import React, {useEffect, useRef, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction"
import idLocale from '@fullcalendar/core/locales/id';
import EventCalenderModal from "./EventCalenderModal";
import axios from "axios";
import {viewAlert} from "../SweetAlert/SweetAlert";
import {getAgendas} from "../api/agendas";

const CalendersView = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isTitle, setTitle] = useState({});
    const [eventList, setEventList] = useState([])
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            return;
        }
        isMounted.current = true;
        getAgendas().then((res) => {
            setEventList(res)
            console.log(res)
        }).catch(err => viewAlert("Massalah!", err, "error"))

        return () => {};
    }, []);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setTitle({});
        setIsModalOpen(false);
    };

    const handleEventClick = (e) => {
        eventList.forEach(evt => {
            if (e.el.innerText === evt.title) {
                setTitle({
                    title: e.el.innerText,
                    content: evt.text,
                });
                openModal()
            }
        })
    }

    const handleDateClick = (arg) => {
        console.log(arg)
    }

    const renderEventContent = (eventInfo) => {
        return (
            <div className="cursor-pointer">
                <b>{eventInfo.timeText}</b>
                <i>{eventInfo.event.title}</i>
            </div>
        );
    };

    return (
        <div className="">
            <FullCalendar
                plugins={[ dayGridPlugin, interactionPlugin ]}
                dateClick={handleDateClick}
                initialView="dayGridMonth"
                events={eventList}
                eventClick={handleEventClick}
                eventContent={renderEventContent}
                displayEventTime={true}
                locale={idLocale}
                selectable={true}
            />
            <EventCalenderModal isOpen={isModalOpen} onClose={closeModal} events={isTitle} />
        </div>
    );
};

export default CalendersView;