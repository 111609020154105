import axios from "axios";
import newsThumbnail from "../components/NewsThumbnail";

export const getAgendas = () => {
    return new Promise(async (resolve, reject) => {
        await axios.get('/api/agenda').then((res) => {
            if (res.status === 200) {
                let arrList = [];
                res.data.data.forEach((list) => {
                    let newDate = list.endDateTime.split('T')[0].split("-");
                    let day = "";

                    let num = parseInt(newDate[2], 10) + 1;
                    let newStr = num.toString().padStart(2, '0');

                    let useDate = newDate[0] + "-" + newDate[1] + "-" + newStr
                    console.log(useDate);
                    let datalist = {
                        title: list.title,
                        start: list.startDateTime.split('T')[0],
                        end: useDate,
                        text: list.description
                    }
                    console.log(datalist);
                    arrList.push(datalist);
                })
                resolve(arrList);
            }
        }).catch((err) => {
            return err;
        })
    })
}