import React, {useEffect, useRef, useState} from 'react';
import Jumbotron from "../components/Jumbotron";
import axios from "axios";
import BeritaList from "../components/berita/BeritaList";
import DetailBerita from "../components/berita/DetailBerita";
import KegiatanList from "../components/berita/KegiatanList";
import {viewAlert} from "../SweetAlert/SweetAlert";
import {getArticleById, getArticles, getCategories} from "../api/articles";

const qs = require("qs");

const BeritaContent = () => {
    const [berita, setBerita] = useState([])
    const [kegiatan, setKegiatan] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(5);
    const [selectedNews, setSelectedNews] = useState(null);
    const [param, setParam] = useState('')

    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            return
        }
        isMounted.current = true;
        getCategories().then((res) => {
            let category = [];
            const responsecat = res.data;
            responsecat.forEach((data) => {
                category.push({
                    id: data.id,
                    name: data.name,
                });
            })
            return category
        }).then(async  (respn) => {
            let beritaTemp = []
            await getArticles().then((res) => {
                const response = res.data;

                response.forEach((data) => {
                    respn.forEach((category) => {
                        if (data.categoryId === category.id) {
                            data.categoryName = category.name;
                            beritaTemp.push(data)
                        }
                    })
                })

                setTotalPages(Math.ceil(parseInt(res.totalFiltered) / limit))
            }).catch((err) => {
                viewAlert('Masalah!', err, 'error')
            });

            let tempArtikle = [];
            let tempKegiatan = [];
            beritaTemp.forEach((category) => {
                if (category.categoryName === "berita") {
                    tempArtikle.push(category)
                } else if (category.categoryName === "kegiatan") {
                    tempKegiatan.push(category)
                }
            })

            setBerita(tempArtikle)
            setKegiatan(tempKegiatan)

            console.log(tempArtikle)

        }).catch(err => viewAlert('Masalah!', err, 'error'))

        return () => {}
    }, [currentPage]);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
        scrollToTop()
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
        scrollToTop();
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleNewsClick = (id) => {
        try {
            getArticleById(id).then((res) => {
                setSelectedNews(res);
            })
        } catch (e) {
            viewAlert('Masalah', e, 'error')
        }
    };

    const onClickBackHandle = () => {
        setSelectedNews(null);
    }

    return (
        <div>
            <Jumbotron title='Berita & Kegiatan' />
            <div className='grid desktop:grid-flow-row-dense desktop:grid-cols-3 phone:grid-cols-1 h-[100%] bg-white desktop:ml-20 desktop:mr-20'>
                <div className='flex items-start justify-center desktop:col-span-2'>
                    <div className='w-full mt-5'>
                        {/*List Berita*/}
                        <p className='text-3xl phone:ml-10 phone:mt-5 desktop:mb-5'>Berita Terbaru</p>
                        <BeritaList list={berita} onNewClick={handleNewsClick}/>
                        <div className='flex items-center justify-center mt-10 mb-10'>
                            <button className='bg-gray-100 p-2 hover:bg-gray-200 rounded-lg mr-5' onClick={prevPage}
                                    disabled={currentPage === 1}>Prev
                            </button>
                            <span> {currentPage} ... {totalPages} </span>
                            <button className='bg-gray-100 p-2 hover:bg-gray-200 rounded-lg ml-5' onClick={nextPage}
                                    disabled={currentPage === totalPages}>Next
                            </button>
                        </div>

                    </div>
                </div>
                <div className='flex items-start'>
                    <div className='grid grid-cols-1 gap-5 m-10'>
                        <p className='text-3xl'>Kegiatan</p>
                        {kegiatan.length > 0 ? (
                            <div>
                                <KegiatanList kegiatan={kegiatan} onNewClick={handleNewsClick}/>
                            </div>
                        ) : (
                            <div className="max-w-sm bg-white rounded-xl overflow-hidden">
                                <div className="p-6">
                                    <p className='text-gray-400'>Kegiatan Kosong..</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BeritaContent;