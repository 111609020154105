import React from 'react';
import {Link} from "react-router-dom";

const NewsThumbnail = ({ imageUrl, title, description, date, big }) => {
    const formatDate = (date) => {
        if (date === null) {
            return "";
        }
        return date.split('T')[0] + ' ' + date.split('T')[1].replace('Z', '').split(':')[0] + ":" + date.split('T')[1].replace('Z', '').split(':')[1]
    }

    return (
        <div className="max-w-sm flex rounded overflow-hidden shadow-lg m-5">
            <img src={imageUrl} alt={title} className={`w-[50%] h-32 ${big}`} />
            <div className="px-1 py-1">

                <div className='flex flex-col justify-between'>
                    <div>
                        <div className="font-bold text-[10px] w-50">{title}</div>
                    </div>
                    {/*<div>*/}
                    {/*    <article className="text-gray-700 truncate" dangerouslySetInnerHTML={{__html: description}}></article>*/}
                    {/*</div>*/}
                    <div>
                        <div className="flex justify-start items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M12 6V12L16 14"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M12 6V12L8 14"
                                />
                                <circle cx={12} cy={12} r={10}/>
                            </svg>
                            <p className="text-gray-400 text-[13px] ml-1">{formatDate(date)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsThumbnail;