import React, {useEffect, useState} from 'react';
import Jumbotron from "../components/Jumbotron";
import RadioButton from "../components/RadioButton";
import {viewAlert} from "../SweetAlert/SweetAlert";
import axios from "axios";
import {getIklan} from "../api/iklan";
import ListIklan from "../components/ListIklan";

const SurveiMasyarakatContent = () => {
    let options = [
        {
            id: "1",
            question: "Usia Responden",
            options: [
                { value: '18-25 tahun', label: '18-25 tahun' },
                { value: '26-35 tahun', label: '26-35 tahun' },
                { value: '36-45 tahun', label: '36-45 tahun' },
                { value: '46-55 tahun', label: '46-55 tahun' },
                { value: 'Di atas 55 tahun', label: 'Di atas 55 tahun' },
            ],
            answer: ""
        },
        {
            id: "2",
            question: "Jenis Kelamin Responden",
            options: [
                { value: 'laki-laki', label: 'Laki-Laki' },
                { value: 'perempuan', label: 'Perempuan' },
            ],
            answer: ""
        },
        {
            id: "3",
            question: "Pendidikan Terakhir Responden",
            options: [
                { value: 'SD', label: 'SD' },
                { value: 'SMP', label: 'SMP' },
                { value: 'SMA', label: 'SMA' },
                { value: 'Diploma', label: 'Diploma' },
                { value: 'Sarjana', label: 'Sarjana' },
                { value: 'Pascasarjana', label: 'Pascasarjana' },
            ],
            answer: ""
        },
        {
            id: "4",
            question: "Pekerjaan Responden",
            options: [
                { value: 'Pegawai Negeri', label: 'Pegawai Negeri' },
                { value: 'Swasta', label: 'Swasta' },
                { value: 'Wiraswasta', label: 'Wiraswasta' },
                { value: 'Pelajar/Mahasiswa', label: 'Pelajar/Mahasiswa' },
                { value: 'Lainnya', label: 'Lainnya' },
            ],
            answer: ""
        },
        {
            id: "5",
            question: "Seberapa baik Anda memahami peran dan fungsi Inspektorat Daerah?",
            options: [
                { value: 'Sangat paham', label: 'Sangat paham' },
                { value: 'Paham', label: 'Paham' },
                { value: 'Cukup paham', label: 'Cukup paham' },
                { value: 'Kurang paham', label: 'Kurang paham' },
                { value: 'Tidak paham sama sekali', label: 'Tidak paham sama sekali' },
            ],
            answer: ""
        },
        {
            id: "6",
            question: "Bagaimana Anda menilai transparansi Inspektorat Daerah dalam melaporkan hasil pengawasan dan audit?",
            options: [
                { value: 'Sangat transparan', label: 'Sangat transparan' },
                { value: 'Transparan', label: 'Transparan' },
                { value: 'Cukup transparan', label: 'Cukup transparan' },
                { value: 'Kurang transparan', label: 'Kurang transparan' },
                { value: 'Tidak transparan sama sekali', label: 'Tidak transparan sama sekali' },
            ],
            answer: ""
        },
        {
            id: "7",
            question: "Seberapa sering Anda melihat laporan atau informasi dari Inspektorat Daerah?",
            options: [
                { value: 'Sangat sering', label: 'Sangat sering' },
                { value: 'Sering', label: 'Sering' },
                { value: 'Cukup Sering', label: 'Cukup Sering' },
                { value: 'Jarang', label: 'Jarang' },
                { value: 'Tidak pernah', label: 'Tidak pernah' },
            ],
            answer: ""
        },
        {
            id: "8",
            question: "Bagaimana Anda menilai akuntabilitas Inspektorat Daerah dalam menjalankan tugasnya?",
            options: [
                { value: 'Sangat akuntabel', label: 'Sangat akuntabel' },
                { value: 'Akuntabel', label: 'Akuntabel' },
                { value: 'Cukup akuntabel', label: 'Cukup akuntabel' },
                { value: 'Kurang akuntabel', label: 'Kurang akuntabel' },
                { value: 'Tidak akuntabel sama sekali', label: 'Tidak akuntabel sama sekali' },
            ],
            answer: ""
        },
        {
            id: "9",
            question: "Apakah Anda merasa bahwa Inspektorat Daerah bekerja secara efektif dalam mencegah dan menindaklanjuti penyimpangan dalam pemerintahan?",
            options: [
                { value: 'Sangat efektif', label: 'Sangat efektif' },
                { value: 'Efektif', label: 'Efektif' },
                { value: 'Cukup efektif', label: 'Cukup efektif' },
                { value: 'Kurang efektif', label: 'Kurang efektif' },
                { value: 'Tidak efektif sama sekali', label: 'Tidak efektif sama sekali' },
            ],
            answer: ""
        },
        {
            id: "10",
            question: "Seberapa puas Anda dengan kinerja Inspektorat Daerah secara keseluruhan?",
            options: [
                { value: 'Sangat puas', label: 'Sangat puas' },
                { value: 'Puas', label: 'Puas' },
                { value: 'Cukup puas', label: 'Cukup puas' },
                { value: 'Kurang puas', label: 'Kurang puas' },
                { value: 'Tidak puas sama sekali', label: 'Tidak puas sama sekali' },
            ],
            answer: ""
        },
        {
            id: "11",
            question: "Seberapa puas Anda dengan kinerja Inspektorat Daerah secara keseluruhan?",
            options: [
                { value: 'Sangat puas', label: 'Sangat puas' },
                { value: 'Puas', label: 'Puas' },
                { value: 'Cukup puas', label: 'Cukup puas' },
                { value: 'Kurang puas', label: 'Kurang puas' },
                { value: 'Tidak puas sama sekali', label: 'Tidak puas sama sekali' },
            ],
            answer: ""
        }
    ];

    const [iklan, setIklan] = useState([])

    useEffect(() => {
        getIklan().then(res => {
            setIklan(res.data)
        })
    })

    const handleSubmitSurvey = (e) => {
        e.preventDefault();
        let listanswer = []
        for (let i = 0; i < options.length; i++) {
            if (options[i].id === e.target.childNodes[0].childNodes[i].id) {
                let jawaban = e.target.childNodes[0].childNodes[i].childNodes[1].childNodes[1].children[0].innerText;
                if (jawaban.split(": ")[1] === undefined) {
                    listanswer.push(false);
                } else {
                    listanswer.push(true);
                }
                options[i].answer = jawaban.split(": ")[1] === undefined ? "Tidak Menjawab" : jawaban.split(": ")[1];
            }
        }

        if (listanswer.includes(false)) {
            viewAlert('Informasi', 'Anda Belum mengisi semua survey yang ada.', 'info')
        } else {
            handleSendData().then(res => {
                viewAlert('Berhasil', 'Survei berhasil di submit', 'success')
            }).catch(err => viewAlert('Masalah', 'Survei Gagal di submit (Dalam Tahap development)', 'error'));
        }
    }

    const handleSendData = () => {
        return new Promise(async (resolve, reject) => {
            await axios.post("/submitsurvey", JSON.stringify(options))
                .then(response => {
                    console.log(response.status);
                })
                .catch(err => reject(err))
        })
    }

    return (
        <div className='bg-gray-200'>
            <Jumbotron title='Survei Masyarakat'/>
            {/*  Form survey kepuasan masyarakat  */}
            <div className='grid desktop:grid-flow-row-dense desktop:grid-cols-3 shadow-2xl rounded phone:grid-cols-1 h-[100%] bg-white phone:ml-5 desktop:ml-40 desktop:mr-40 phone:mr-5 mt-14'>
                <h1 className='mt-5 text-2xl font-bold pl-10'>Form Input Survey!</h1>
            </div>
            <div className='grid desktop:grid-flow-row-dense desktop:grid-cols-3 shadow-2xl rounded phone:grid-cols-1 h-[100%] bg-white phone:ml-5 desktop:ml-40 desktop:mr-40 phone:mr-5'>
                <div className='flex flex-col desktop:items-start phone:items-center desktop:justify-start phone:justify-center desktop:col-span-2'>
                    <form onSubmit={handleSubmitSurvey}>
                        <ol className='desktop:ml-5 desktop:pl-10 phone:ml-10 phone:mr-5'>
                            {options.map((option) => (
                                <li key={option.id} id={option.id} className='list-decimal pt-2 pb-2'>
                                    <label>{option.question}</label>
                                    <RadioButton options={option.options}/>
                                </li>
                            ))}
                        </ol>

                        <div className='m-10'>
                            <div className='relative items-center'>
                                <button className='bg-blue-950 text-white p-3 rounded-md shadow-xl'>Submit Survei
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='flex items-start'>
                    <div>
                        <ListIklan images={iklan} />
                    </div>
                </div>
            </div>
            <div className='bg-gray-200 h-10 mt-12'></div>
        </div>
    );
};

export default SurveiMasyarakatContent;