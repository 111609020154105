import axios from "axios";

export const getIklan = () => {
    return new Promise(async (resolve, reject) => {
        await axios.get('/api/file/galleries').then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        })
    })
}